import { ref } from 'vue';
import { getProgramsStats } from '../api/stats';

const stats = ref(null);
const isRequested = ref(false);

async function getStatsAsync() {
  if (isRequested.value) {
    return stats;
  }

  isRequested.value = true;

  try {
    const { statusCode, data } = await getProgramsStats();

    if (statusCode !== 200) {
      return stats;
    }

    stats.value = data;

  } catch (error) {
    console.error('Error getting Stats', error);
  }

  return stats;
}

function getStats() {
  if (!isRequested.value) {
    getStatsAsync();
  }

  return stats;
}

export {
  getStats,
  getStatsAsync
};

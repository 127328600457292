import { showToast } from '../utils/domUtils/showNotification';

export const customFetch = async (url, method = 'get', options = {}) => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers
      },
      body: method !== 'get' ? JSON.stringify(options.body) : undefined
    });

    if (!response.ok) {
      showToast('Error', response.statusText, 'error');
      return { statusCode: response.status };
    }
    const data = await response.json();
    return { data, statusCode: response.status };

  } catch (error) {
    console.error('Error API request:', error);
    showToast('Error', 'Error API request', 'error');
    return { error };
  }
};

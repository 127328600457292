import { markRaw } from 'vue';
import { useToast } from 'vue-toastification';
import Toast from '../../molecules/Toast.vue';
import IconError from '../../icons/IconError.vue';

const toast = useToast();

export const showToast = (title, description, type) => {
  const toastId = toast(
    {
      component: markRaw(Toast),
      props: {
        title,
        description: description || 'Occured some error',
        buttonText: 'Close',
        iconClasses: type === 'error' ? 'text-[#FF3D3D]' : 'text-[#7AB27D]',
        icon: markRaw(IconError),
        onClose: () => toast.dismiss(toastId)
      }
    },
    {
      closeOnClick: false,
      hideProgressBar: true,
      icon: false
    }
  );
};

import { createRouter, createWebHistory } from 'vue-router';
import { signOut, doesSessionExist } from '../composables/useAuth.js';

let authorizeResult = null;

async function isAuthorized() {
  if (authorizeResult === null) {
    const _isAuthorized = await doesSessionExist();
    authorizeResult = { isAuthorized: _isAuthorized };
  }

  return authorizeResult.isAuthorized;
}

const routes = [
  {
    path: '/programs/',
    name: 'Programs',
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivatePrograms' });
      } else {
        next({ name: 'PublicPrograms' });
      }
    }
  },
  {
    path: '/programs/:programId',
    name: 'Program',
    props: true,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivateProgram', params: _to.params });
      } else {
        next({ name: 'PublicProgram', params: _to.params });
      }
    }
  },

  {
    path: '/category/:categoryId',
    name: 'Category',
    props: true,
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next({ name: 'PrivateCategory', params: _to.params });
      } else {
        next({ name: 'PublicCategory', params: _to.params });
      }
    }
  },

  {
    path: '/',
    component: () => import('../pages/MainPage.vue'),
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    },
    children: [
      {
        path: '',
        component: () => import('../pages/StartPage.vue')
      },
      {
        path: '/features',
        component: () => import('../pages/FeaturesPage.vue')
      },
      {
        path: '/pricing',
        component: () => import('../pages/PricingPage.vue')
      },
      {
        path: '/company',
        component: () => import('../pages/CompanyPage.vue')
      },
      {
        path: '/programs',
        name: 'PublicPrograms',
        component: () => import('../pages/LibraryPage.vue'),
        props: { isPublic: true }
      },
      {
        path: '/programs/:programId',
        name: 'PublicProgram',
        component: () => import('../pages/ProgramPage.vue'),
        props: true
      },
      {
        path: '/category/:categoryId',
        name: 'PublicCategory',
        component: () => import('../pages/CategoryPage.vue'),
        props: true
      }
    ]
  },

  {
    path: '/user',
    component: () => import('../pages/LoggedUserPage.vue'),
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next();
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: '',
        redirect: '/library'
      },
      {
        path: '/library',
        component: () => import('../pages/LibraryPage.vue')
      },
      {
        path: '/programs',
        name: 'PrivatePrograms',
        component: () => import('../pages/ProgramsPage.vue')
      },
      {
        path: '/programs/:programId',
        name: 'PrivateProgram',
        component: () => import('../pages/ProgramPage.vue'),
        props: true
      },
      {
        path: '/category/:categoryId',
        name: 'PrivateCategory',
        component: () => import('../pages/CategoryPage.vue'),
        props: true
      },
      {
        path: '/files',
        component: () => import('../pages/FilesPage.vue')
      },
      {
        path: '/my-profile',
        name: 'PrivateMyProfile',
        component: () => import('../pages/MyProfile.vue')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('../pages/LoginPage.vue'),
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    }
  },
  {
    path: '/welcome',
    component: () => import('../pages/LoginPage.vue'),
    props: { isWelcome: true },
    beforeEnter: async (_to, _from, next) => {
      if (await isAuthorized()) {
        next('/user');
      } else {
        next();
      }
    }
  },
  {
    path: '/logout',
    component: true, // NOTE: this is a hack to make the beforeEnter hook work
    beforeEnter: async () => {
      await signOut();
      router.push('/');
    }
  },
  {
    path: '/terms',
    component: () => import('../pages/TermsPage.vue')
  },

  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../pages/NotFoundPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../pages/NotFoundPage.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    const element = document.querySelector('main');
    if (element) {
      element.scrollTo(0, 0);
      return null;
    }

    return { top: 0 };
  }
});

router.afterEach(() => {
  // reset the authorization check
  authorizeResult = null;
});

export default router;
